html {
  height: 100%;
  font-family: "KGPrimaryPenmanship";
}

body, #root, .app {
  height: 100%;
}

.app {
  text-align: center;

  display: flex;
  flex-direction: column;
}

.bead {
  cursor: pointer;
}

.stage-parent {
  flex: 1;
}

.button-container {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  justify-content: center;
}

#checkWork {
  position: absolute;
  z-index: 2;
  pointer-events: auto;

  padding: 0.5rem 1rem;
  height: auto;

  font-family: "KGPrimaryPenmanship";
  font-size: 3rem;
  text-transform: none;
}

/*
 Gets stuck after touch on mobile. Just gonna leave it off.
#checkWork:hover {
  background-color: #9AD2CB;
  border: 0.1rem solid #9AD2CB;
}
*/

#checkWork, #checkWork[disabled] {
  background-color: #23B5D3;
  border: 0.1rem solid #23B5D3;
}

#checkWork[disabled] {
  opacity: 1;
}
